import React, { useState, useEffect } from 'react';
import logo_min from '../img/logo-min.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Top.css';

import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import {getCompanyName, getCompanyAddress, getCompanyFoundDay, getCompanyMailAddress, getCompanyBusiness, getCompanyMemberWithPost} from "../apis/company"

function App() {

  /*
  const [company_name, setCompanyName] = useState(null);
  const [company_address, setCompanyAddress] = useState([]);
  const [company_found_day, setCompanyFoundDay] = useState(null);
  const [company_mail_address, setCompanyMailAddress] = useState(null);
  const [company_business, setCompanyBusiness] = useState([]);
  const [company_member_with_post, setCompanyMemberWithPost] = useState([]);
  */

  useEffect(async () => 
  {
    // 🙅モウティ版無限ループ🙅
    // getCompanyName()初回レンダリング時に実行されるが、
    // setCompanyNameによるさらに再レンダリングされる。
    // その場合、最初のgetCompanyName()とsetCompanyNameによる再レンダリング後のgetCompanyName()は別物と判定されるため、
    // 無限実行が発生する。
    // 回避方法はuseEffect(lambda式, [])の用に第二引数に空の配列を指定してあげることである。
    /* setCompanyName(getCompanyName()); */

    // 会社情報設定
    /*
    setCompanyName(await getCompanyName());
    setCompanyAddress(await getCompanyAddress());
    setCompanyFoundDay(await getCompanyFoundDay());
    setCompanyMailAddress(await getCompanyMailAddress());
    setCompanyBusiness(await getCompanyBusiness()); 
    setCompanyMemberWithPost(await getCompanyMemberWithPost());
    */

  }, []);

  return (
    <div className="App">

      <Container className="App-main">
        <Row>
          <div className="App-heading-image">
            <Image src={logo_min}  alt="Dream Panda Works" fluid/>
          </div>
        </Row>

        <Table responsive="md" style={{"backgroundColor": "#FFFFFF"}}>
          <tbody>
            <tr>
              <td>社名</td>
              <td id="company_name">{ "Dream Panda Works合同会社" }</td>
            </tr>
            <tr>
              <td>本社所在地</td>
              <td id="company_address">{ "〒214-0014" }<br />
              { "神奈川県川崎市多摩区登戸2432-1 Bluewater Building 7階" }</td>
            </tr>
            <tr>
              <td>設立</td>
              <td>{ "2021年7月29日" }</td>
            </tr> 
            <tr>
              <td>電子メール</td>
              <td><a href={`mailto:${"info@dreampandaworks.com"}`} className="App-p-link">{ "info@dreampandaworks.com" }</a></td>
            </tr>
            <tr>
              <td>事業内容</td>
              <td>{ "コンピュータのソフトウェア及びハードウェアの企画、研究、開発、設計、製造、販売、保守、リース、賃貸及び輸出入並びにこれらに関するコンサルティング業務" }<br />
              { "ジャイアントパンダの保護と生息地の保全に関する事業" }</td>
            </tr>
            <tr>
              <td>代表者並びに役員</td>
              <td>{ "代表社員 加藤 穏" }<br />
              { "業務執行社員 大町 怜司" }</td>
            </tr>      
          </tbody>
        </Table>
      </Container>

    </div>
  )}

export default App;
