import React from 'react';
import ReactDOM from 'react-dom';
import Top from './pages/Top';

import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
const root = document.getElementById('root');

ReactDOM.render(
    <Router>
      <Route
        render={({ location }) => (
            <Switch location={location} key={location.pathname}>
              <Route exact path="/" component={Top} />
            </Switch>
        )}
      />
  </Router>
,root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
